export const strict = false;
import marks from "~/apollo/queries/marks"
import settings from "~/apollo/queries/settings"
import banksQuery from "~/apollo/queries/bank/banks.gql"

export const state = () => ({
  site_id: null,
  domain: ""
});
export const getters = {
  site_id: state => {
    return state.site_id;
  },
  domain: state => {
    return state.domain;
  }
};
export const actions = {
  async nuxtServerInit({ dispatch, commit, state }, { req, app, $config }) {
    let site_id = 393;
    commit("SET_SITE_ID", $config.site_id);
    commit("SET_DOMAIN", $config.domain);

    let client = app.apolloProvider.defaultClient;
    // TODO получаю дефолтный процент тут так как в state нет экземпляра контекста
    commit("banks/SET_PERCENT", $config.default_percent);

    // if (req.headers.host === "xn----7sbabh4clpudlde.xn--p1ai") {
    //   commit("SET_SITE_ID", $config.site_id_mirror1);
    //   commit("SET_DOMAIN", $config.domain_mirror1);
    // }

    //TODO НАСТРОЙКИ САЙТА
    let currentSettings = {};
    let settingsResponse = await client.query({
      query: settings,
      variables: { site_id: state.site_id }
    });
    settingsResponse.data.settings.settings.map(value => {
      currentSettings[value.key] = value.value;
    });

    let banks = await client.query({
      query: banksQuery,
      variables: { site_id: state.site_id }
    });
    commit("settings/settings/SET_SETTINGS", currentSettings);
    commit("banks/SET_BANKS", banks.data.banks);

    // TODO ПОЛУЧАЮ СПИСОК МАРОК
    let response = await client.query({
      query: marks,
      variables: { site_id: state.site_id, category: "cars" }
    });
    commit("marks/marks/SET_ALL_MARKS", response.data.marks);

    let responseNew = await client.query({
      query: marks,
      variables: { site_id: state.site_id, category: "new" }
    });
    commit("marks/marks/SET_ALL_MARKS_NEW", responseNew.data.marks);
  },
  async request({ state }, { query, variables }) {
    let assignVariables = {
      site_id: state.site_id
    };
    let client = this.app.apolloProvider.defaultClient;
    let params = { ...assignVariables, ...variables };
    return await client.query({
      query: query,
      variables: this.$removeEmptyObjects(params),
      fetchPolicy: "no-cache"
    });
  }
};
export const mutations = {
  SET_SITE_ID(state, data) {
    state.site_id = data;
  },
  SET_DOMAIN(state, data) {
    state.domain = data;
  }
};
