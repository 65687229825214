import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _29b2e1c8 = () => interopDefault(import('../pages/About.vue' /* webpackChunkName: "pages/About" */))
const _10115af2 = () => interopDefault(import('../pages/Banks.vue' /* webpackChunkName: "pages/Banks" */))
const _4e481846 = () => interopDefault(import('../pages/Buyout.vue' /* webpackChunkName: "pages/Buyout" */))
const _4f0c7f30 = () => interopDefault(import('../pages/Contacts.vue' /* webpackChunkName: "pages/Contacts" */))
const _5bd89539 = () => interopDefault(import('../pages/Cookie.vue' /* webpackChunkName: "pages/Cookie" */))
const _641e3751 = () => interopDefault(import('../pages/Credit/index.vue' /* webpackChunkName: "pages/Credit/index" */))
const _a83e2bdc = () => interopDefault(import('../pages/Favorites.vue' /* webpackChunkName: "pages/Favorites" */))
const _3a9ad212 = () => interopDefault(import('../pages/Installment/index.vue' /* webpackChunkName: "pages/Installment/index" */))
const _3bab20fa = () => interopDefault(import('../pages/Privacy.vue' /* webpackChunkName: "pages/Privacy" */))
const _4aae82d4 = () => interopDefault(import('../pages/Reviews.vue' /* webpackChunkName: "pages/Reviews" */))
const _7b6b471e = () => interopDefault(import('../pages/Thanks.vue' /* webpackChunkName: "pages/Thanks" */))
const _5c4ad67a = () => interopDefault(import('../pages/Trade-in.vue' /* webpackChunkName: "pages/Trade-in" */))
const _6e1f4c1d = () => interopDefault(import('../pages/Credit/_bank/index.vue' /* webpackChunkName: "pages/Credit/_bank/index" */))
const _5c0ec6a6 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _60abf08a = () => interopDefault(import('../pages/_category/index.vue' /* webpackChunkName: "pages/_category/index" */))
const _df9c3750 = () => interopDefault(import('../pages/_category/_mark/index.vue' /* webpackChunkName: "pages/_category/_mark/index" */))
const _9bee21d2 = () => interopDefault(import('../pages/_category/_mark/_model/index.vue' /* webpackChunkName: "pages/_category/_mark/_model/index" */))
const _7de39763 = () => interopDefault(import('../pages/_category/_mark/_model/_car/index.vue' /* webpackChunkName: "pages/_category/_mark/_model/_car/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'active',
  linkExactActiveClass: 'active',
  scrollBehavior,

  routes: [{
    path: "/About",
    component: _29b2e1c8,
    name: "About"
  }, {
    path: "/Banks",
    component: _10115af2,
    name: "Banks"
  }, {
    path: "/Buyout",
    component: _4e481846,
    name: "Buyout"
  }, {
    path: "/Contacts",
    component: _4f0c7f30,
    name: "Contacts"
  }, {
    path: "/Cookie",
    component: _5bd89539,
    name: "Cookie"
  }, {
    path: "/Credit",
    component: _641e3751,
    name: "Credit"
  }, {
    path: "/Favorites",
    component: _a83e2bdc,
    name: "Favorites"
  }, {
    path: "/Installment",
    component: _3a9ad212,
    name: "Installment"
  }, {
    path: "/Privacy",
    component: _3bab20fa,
    name: "Privacy"
  }, {
    path: "/Reviews",
    component: _4aae82d4,
    name: "Reviews"
  }, {
    path: "/Thanks",
    component: _7b6b471e,
    name: "Thanks"
  }, {
    path: "/Trade-in",
    component: _5c4ad67a,
    name: "Trade-in"
  }, {
    path: "/Credit/:bank",
    component: _6e1f4c1d,
    name: "Credit-bank"
  }, {
    path: "/",
    component: _5c0ec6a6,
    name: "index"
  }, {
    path: "/:category",
    component: _60abf08a,
    name: "category"
  }, {
    path: "/:category/:mark",
    component: _df9c3750,
    name: "category-mark"
  }, {
    path: "/:category/:mark/:model",
    component: _9bee21d2,
    name: "category-mark-model"
  }, {
    path: "/:category/:mark/:model/:car",
    component: _7de39763,
    name: "category-mark-model-car"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
